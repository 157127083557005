<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'TentangKami',

    metaInfo: { title: 'Tentang Kami' },

    extends: View,

    mixins: [
      LoadSections([
        'about-us',
        'story',
        // 'company-value',
        // 'boards',
        // 'financial-report',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
